import React from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import Signup from './signup';


function MainAccountSignup() {
  return (
    <div className="content-wrapper">
      <Menu />
      <Signup />
      <Footer />
    </div>
  );
}

export default MainAccountSignup;