import { React } from 'react';
import Menu from './menu';
//import Bookings from '../bookings/bookings';
import Dashboard from '../dashboard/dashboard';


function Home() {

  return (
    <div className="content-wrapper">
      <Menu />
      <Dashboard />
    </div>
  );
}

export default Home;