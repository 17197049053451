import * as FIREBASE_PATHS from '../config/firebase-path-mapping';
import * as genericDAO from './genericDAO';
import { doc, updateDoc, onSnapshot, collection, query, where, deleteField, getDocs, orderBy } from "firebase/firestore";
import { db } from '../config/firebase-config';

const EMPTY_OBJECT = {};

export async function getBookings() {
    return await genericDAO.getDocuments(FIREBASE_PATHS.BOOKINGS_COLLECTION_PATH);
}

export function getBookingsStream(callback, statusFilters) {
    const subCollectionCRef = collection(db, FIREBASE_PATHS.BOOKINGS_COLLECTION_PATH);

    const queries = statusFilters.map(status => query(subCollectionCRef, where("bookingStatus", "==", status), orderBy('generalDetails.bookingDate', 'asc')));

    const unsubscribeFns = queries.map((q, index) =>
        onSnapshot(q, (snapshot) => {
            const docs = snapshot.docs.map((doc) => ({
                refId: doc.id,
                ...doc.data()
            }));
            callback(docs, statusFilters[index]);
        }, (error) => {
            console.error(`Error fetching real-time updates for ${statusFilters[index]}:`, error);
        })
    );

    return () => unsubscribeFns.forEach(unsub => unsub());
}
export async function getBookingById(typeId) {
    return await genericDAO.getDocumentById(FIREBASE_PATHS.BOOKINGS_COLLECTION_PATH, typeId.toString())
}

export async function getBookingByRefIdAndEmail(bookingRefId, emailId) {
    try {

        const bookingsCollection = collection(db, FIREBASE_PATHS.BOOKINGS_COLLECTION_PATH);

        // Create a query to filter by status and nested emailId
        const q = query(
            bookingsCollection,
            where('bookingReferenceId', '==', bookingRefId),
            where('passengerDetails.email', '==', emailId)
        );

        // Execute the query
        const querySnapshot = await getDocs(q);

        // Check if any documents were found
        if (querySnapshot.empty) {
            console.log('No matching bookings found.');
            return [];
        }

        // Process the documents
        const bookings = [];
        querySnapshot.forEach((doc) => {
            bookings.push({ id: doc.id, ...doc.data() });
        });

        return bookings;
        /*
        const bookingByIdRef = doc(db, FIREBASE_PATHS.BOOKING_COLLECTION_PATH, bookingRefId);
        const document = await getDoc(bookingByIdRef);
        return document.data();
        */

    } catch (error) {
        console.error('getDocumentById: Exception occured while fetching the data ', error);
    }
    return EMPTY_OBJECT;
}

export async function updateBookingWithCancelledPaymentStatus(bookingRefId, userId, cancellationReason) {
    const bookingByIdRef = doc(db, FIREBASE_PATHS.BOOKINGS_COLLECTION_PATH, bookingRefId);
    await updateDoc(bookingByIdRef, {
        bookingStatus: 'CANCELLED',
        updatedBy: userId,
        cancellationReason: cancellationReason
    });
}

export async function updateBookingWithCancelledPaymentStatusAndRefund(bookingRefId, refundAmount, userId, cancellationReason) {
    const bookingByIdRef = doc(db, FIREBASE_PATHS.BOOKINGS_COLLECTION_PATH, bookingRefId);
    await updateDoc(bookingByIdRef, {
        bookingStatus: 'CANCELLED',
        refundAmount: refundAmount,
        updatedBy: userId,
        cancellationReason: cancellationReason
    });
}




export async function updateBookingStatus(bookingId, status, driver, userId) {
    try {
      const bookingRef = doc(db, FIREBASE_PATHS.BOOKINGS_COLLECTION_PATH, bookingId);
  
      let updateData = {
        bookingStatus: status,
        updatedBy: userId,
      };
  
      if (driver) {
        // If a driver is provided, assign driver details
        const driverData = {
          driverId: driver.driverId || '',
          firstName: driver.firstName || '',
          lastName: driver.lastName || '',
          email: driver.email || '',
          mobileNumber: driver.mobileNumber || ''  // Set default value if undefined
        };
  
        updateData.driverAssigned = driverData;
      } else {
        // If no driver is provided (unassign), remove driver details
        updateData.driverAssigned = deleteField();
      }
  
      // Update the booking document with the new status and driver details (or remove them)
      await updateDoc(bookingRef, updateData);
  
      console.log("Booking status and driver details updated successfully");
    } catch (error) {
      console.error("Error updating booking status and driver details:", error);
    }
  }
  