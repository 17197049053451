import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/common/home';
import MainChauffeur from './components/chauffeur/mainChauffeur';
import MainManageBooking from './components/manage-booking/main-manage-booking';
import MainAccount from './components/account/main-account';
import MainAccountSignup from './components/account/main-account-signup';
import MainAccountSignupSuccess from './components/account/main-account-signup-success';
import PrivateRoute from './contexts/privateRoute';
import MainAssignBooking from './components/manage-booking/main-assign-booking';
import MainBookings from './components/bookings/main-bookings';


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          } />
          <Route path="/bookings" element={
            <PrivateRoute>
              <MainBookings />
            </PrivateRoute>
          } />
          <Route path="/MainChauffeur" element={
            <PrivateRoute>
              <MainChauffeur />
            </PrivateRoute>
          } />
          <Route path="/manage-booking" element={
            <PrivateRoute>
              <MainManageBooking />
            </PrivateRoute>
          } />
          
          <Route path="/assign-booking" element={
            <PrivateRoute>
              <MainAssignBooking />
            </PrivateRoute>
          } />
          <Route path="/signin" element={<MainAccount />} />
          <Route path="/signup" element={<MainAccountSignup />} />
          <Route path="/signupSuccess" element={<MainAccountSignupSuccess />} />
        </Routes>
      </Router>


    </>
  );
}

export default App;