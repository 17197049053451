import { db } from '../config/firebase-config';
import { doc, setDoc } from 'firebase/firestore';


export async function createUserProfile(user) {

    try {
        // Create a user profile in Firestore
        await setDoc(doc(db, "users", user.uid), {
            entityId: user.entityId,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            role: 'Admin',
            createdAt: new Date()
        });

        console.log("User profile saved successfully:", user);
    } catch (error) {
        console.error("Error while creating user profile:", error.message);
    }
};
