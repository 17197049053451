import React from 'react';
import Menu from '../common/menu';
import ManageBooking from './manage-booking';


function MainManageBooking() {
    return (
        <div className="content-wrapper">
            <Menu />
            <ManageBooking />

        </div>
    );
}

export default MainManageBooking;