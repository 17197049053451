import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FaCar, FaUser, FaDollarSign, FaTruck, FaTimesCircle, FaCheckCircle } from 'react-icons/fa'; // Import icons
import { getBookingsStream } from '../../db/bookingsDAO';
import BookingStatus from '../enums/BookingStatus';

function Dashboard() {

    const [dashboardData, setDashboardData] = useState({
        totalTripsBooked: 0,
        totalTripsAssigned: 0,
        totalTripsCancelled: 0,
        totalDollarEarned: 0,
    });

    useEffect(() => {
        // Simulate fetching dashboard data from an API or database
        const unsubscribe = getBookingsStream((data, status) => {
            if (status === BookingStatus.CONFIRMED) {
                setDashboardData((prevDetails) => ({
                    ...prevDetails,
                    totalTripsBooked: data.length
                }));

            } else if (status === BookingStatus.DRIVER_ASSIGNED) {
                setDashboardData((prevDetails) => ({
                    ...prevDetails,
                    totalTripsAssigned: data.length
                }));
            } else if (status === BookingStatus.CANCELLED) {
                setDashboardData((prevDetails) => ({
                    ...prevDetails,
                    totalTripsCancelled: data.length
                }));
            }

        }, [BookingStatus.CONFIRMED, BookingStatus.DRIVER_ASSIGNED, BookingStatus.CANCELLED]);

        return () => unsubscribe && unsubscribe();

    }, []);


    return (
        <div className="container mt-4">
            <h2 className="mb-4">Dashboard Overview</h2>
            <div className="row g-4">
                {/* Total Trips Booked */}
                <div className="col-lg-4 col-md-6">
                    <Link to="/bookings" className="text-decoration-none">
                        <div className="card dashboard-card bg-info text-white shadow-sm">
                            <div className="card-body d-flex align-items-center">
                                <FaCar className="icon me-3" />
                                <div>
                                    <h5 className="card-title">Total New Bookings</h5>
                                    <p className="card-text display-6">{dashboardData.totalTripsBooked}</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                {/* Total Trips Assigned */}
                <div className="col-lg-4 col-md-6">
                    <Link to="/bookings" className="text-decoration-none">
                        <div className="card dashboard-card bg-success text-white shadow-sm">
                            <div className="card-body d-flex align-items-center">
                                <FaCheckCircle className="icon me-3" />
                                <div>
                                    <h5 className="card-title">Total Trips Assigned</h5>
                                    <p className="card-text display-6">{dashboardData.totalTripsAssigned}</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                {/* Total Trips Cancelled */}
                <div className="col-lg-4 col-md-6">
                    <Link to="/bookings" className="text-decoration-none">
                        <div className="card dashboard-card bg-danger text-white shadow-sm">
                            <div className="card-body d-flex align-items-center">
                                <FaTimesCircle className="icon me-3" />
                                <div>
                                    <h5 className="card-title">Total Trips Cancelled</h5>
                                    <p className="card-text display-6">{dashboardData.totalTripsCancelled}</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                {/* Total Dollar Earned */}
                <div className="col-lg-4 col-md-6">
                    <Link to="/bookings" className="text-decoration-none">
                        <div className="card dashboard-card bg-warning text-dark shadow-sm">
                            <div className="card-body d-flex align-items-center">
                                <FaDollarSign className="icon me-3" />
                                <div>
                                    <h5 className="card-title">Total Dollar Earned</h5>
                                    <p className="card-text display-6">${dashboardData.totalDollarEarned}</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                {/*
        <div className="col-lg-4 col-md-6">
          <div className="card dashboard-card bg-info text-white shadow-sm">
            <div className="card-body d-flex align-items-center">
              <FaTruck className="icon me-3" />
              <div>
                <h5 className="card-title">Number of Fleets</h5>
                <p className="card-text display-6">{dashboardData.numberOfFleets}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="card dashboard-card bg-secondary text-white shadow-sm">
            <div className="card-body d-flex align-items-center">
              <FaUser className="icon me-3" />
              <div>
                <h5 className="card-title">Number of Chauffeurs</h5>
                <p className="card-text display-6">{dashboardData.numberOfChauffeurs}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="card dashboard-card bg-dark text-white shadow-sm">
            <div className="card-body d-flex align-items-center">
              <FaUser className="icon me-3" />
              <div>
                <h5 className="card-title">Number of Customers</h5>
                <p className="card-text display-6">{dashboardData.numberOfCustomers}</p>
              </div>
            </div>
          </div>
        </div>

        */}
            </div>
        </div>
    );
}

export default Dashboard;
