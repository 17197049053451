import React, { useState } from 'react';
import { saveChauffeur } from '../../db/chauffeurDAO';

const initialFormState = {
    prefix: '',
    firstName: '',
    lastName: '',
    yearOfBirth: '',
    hasExperience: '',
    address: {
        city: 'Brisbane',
        street: '',
        city: '',
        nationality: '',
        suburb: '',
        state: '',
        postcode: ''
    },
    contact: {
        email: '',
        confirmEmail: '',
        phone: ''
    },
    licenseDetails: {
        licenceType: 'open',
        licenceExpiry: '',
        continuousYearsHeld: '',
        hasDriverAuthority: '',
        driverAuthorityExpiry: '',
    },
}

const AddChauffeur = () => {
    const [formData, setFormData] = useState(initialFormState);

    const [successMessage, setSuccessMessage] = useState(null);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const setAddressDetails = (e) => {
        setFormData({
            ...formData,
            address: {
                ...formData.address,
                [e.target.name]: e.target.value
            },
        });
    };

    const setContactDetails = (e) => {
        setFormData({
            ...formData,
            contact: {
                ...formData.contact,
                [e.target.name]: e.target.value
            },
        });
    };

    const setLicenseDetails = (e) => {
        setFormData({
            ...formData,
            licenseDetails: {
                ...formData.licenseDetails,
                [e.target.name]: e.target.value
            },
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        let responseId = saveChauffeur(formData);
        if (responseId) {
            setSuccessMessage("Congratulations! Chauffeur " + formData.firstName + " " + formData.lastName + " has been added succesfully.");
        }
        setFormData(initialFormState);
    };

    return (
        <form onSubmit={handleSubmit} className="container mt-5">

            <div className="col-md-10 col-lg-12 col-xl-10 col-xxl-9 mx-auto text-center">
                <br />
                <br />
                <h6 className="fs-32 text-dark mb-2">Add Chauffeur</h6>
            </div>
            <h4>City*</h4>
            <div className="mb-3">
                <select
                    className="form-select"
                    name="city"
                    value={formData.address.city}
                    onChange={setAddressDetails}
                >
                    <option value="">Select</option>
                    <option value="Brisbane">Brisbane</option>
                    <option value="Sydney">Sydney</option>
                    <option value="Perth">Perth</option>
                    <option value="Adelaide">Adelaide</option>
                </select>

            </div>

            <h4>Do you have Chauffeur or Contracting Experience?*</h4>
            <div className="mb-3">
                <select
                    className="form-select"
                    name="hasExperience"
                    value={formData.hasExperience}
                    onChange={handleChange}
                >
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            </div>

            <h4>Personal Details</h4>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Prefix"
                    name="prefix"
                    value={formData.prefix}
                    onChange={handleChange}
                />
            </div>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="First Name*"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name*"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="mb-3">
                <input
                    type="number"
                    className="form-control"
                    placeholder="Year of Birth*"
                    name="yearOfBirth"
                    value={formData.yearOfBirth}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Nationality as per passport"
                    name="nationality"
                    value={formData.address.nationality}
                    onChange={setAddressDetails}
                />
            </div>

            <h4>Driver Licence Details</h4>
            <div className="mb-3">

                <select
                    className="form-select"
                    name="licenceType"
                    value={formData.licenseDetails.licenceType}
                    onChange={setLicenseDetails}
                >
                    <option value="open">Open</option>
                    <option value="pplate">P Plate</option>
                    <option value="learner">Learner</option>
                </select>

            </div>
            <div className="mb-3">
                <input
                    type="date"
                    className="form-control"
                    placeholder="Driver Licence Expiry"
                    name="licenceExpiry"
                    value={formData.licenseDetails.licenceExpiry}
                    onChange={setLicenseDetails}
                />
            </div>
            <div className="mb-3">
                <input
                    type="number"
                    className="form-control"
                    placeholder="Continuous years licence has been held"
                    name="continuousYearsHeld"
                    value={formData.licenseDetails.continuousYearsHeld}
                    onChange={setLicenseDetails}
                />
            </div>

            <h4>Passenger Transport Driver Authorisation</h4>
            <div className="mb-3">
                <select
                    className="form-select"
                    name="hasDriverAuthority"
                    value={formData.licenseDetails.hasDriverAuthority}
                    onChange={setLicenseDetails}
                >
                    <option value="">Do You Have a Driver Authority?</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            </div>
            <div className="mb-3">
                <input
                    type="date"
                    className="form-control"
                    placeholder="Driver Authority Expiry"
                    name="driverAuthorityExpiry"
                    value={formData.licenseDetails.driverAuthorityExpiry}
                    onChange={setLicenseDetails}
                />
            </div>

            <h4>Address</h4>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Street"
                    name="street"
                    value={formData.address.street}
                    onChange={setAddressDetails}
                />
            </div>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Suburb"
                    name="suburb"
                    value={formData.address.suburb}
                    onChange={setAddressDetails}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="State"
                    name="state"
                    value={formData.address.state}
                    onChange={setAddressDetails}
                />
            </div>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Postcode"
                    name="postcode"
                    value={formData.address.postcode}
                    onChange={setAddressDetails}
                />
            </div>

            <h4>Contact Details (So We Can Contact You)</h4>
            <div className="mb-3">
                <input
                    type="email"
                    className="form-control"
                    placeholder="Email*"
                    name="email"
                    value={formData.contact.email}
                    onChange={setContactDetails}
                    required
                />
            </div>
            <div className="mb-3">
                <input
                    type="email"
                    className="form-control"
                    placeholder="Confirm Email*"
                    name="confirmEmail"
                    value={formData.contact.confirmEmail}
                    onChange={setContactDetails}
                    required
                />
            </div>
            <div className="mb-3">
                <input
                    type="tel"
                    className="form-control"
                    placeholder="Phone*"
                    name="phone"
                    value={formData.contact.phone}
                    onChange={setContactDetails}
                    required
                />
            </div>

            {successMessage && <div className="alert alert-success" role="alert"> {successMessage} </div>}
            <br />
            <button type="submit" className="btn btn-primary">Submit</button>
            <br /> <br />
        </form>
    );
};

export default AddChauffeur;
