const messages = {
    en: {
      "hero.title": "Australia's Chauffeur Service",
      "hero.subtitle": "Experience the difference with premium transfers, tours, and event transport.",
      "hero.trackMyVehicle": "Track my Vehicle",
      "storeBadge.download": "Download the app",
    },
    fr: {
      // French translations...
    },
    // other locales...
  };
  
  export default messages;
  