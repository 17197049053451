import React from 'react';
import Menu from '../common/menu';
import AssignBooking from './assign-booking';


function MainAssignBooking() {
    let a = {};
    return (
        <div className="content-wrapper">
            <Menu />
            <AssignBooking booking={a}/>

        </div>
    );
}

export default MainAssignBooking;