import React, { useState, useEffect } from 'react';
import { signUp, isValidAdminUser } from '../../services/auth-service';
import { useNavigate, Link } from 'react-router-dom';
import { isValidEmail, isNullOrUndefinedOrEmpty } from '../common/utilfn';
import { createUserProfile } from '../../db/userDAO';

function Signup() {

  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [entityId, setEntityId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [authenticatedUser, setAuthenticatedUser] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (authenticatedUser) {
      navigate('/signupSuccess');
    }
  }, [authenticatedUser]);


  const handleSignUp = async (event) => {
    event.preventDefault();

    if (!validateSignUpForm()) {
      return;
    }
    const isAdmin = await isValidAdminUser(email);
    if (!isAdmin) {
      let validationErrors = {};
      validationErrors.userAlreadyExist = (
        <div>
          <p style={{ color: 'red', fontWeight: 'bold' }}>
            Sign-up is restricted to admin users only.
          </p>
          <p style={{ color: 'red' }}>
            Please choose another email address to register.
            If you have forgotten your password, please click on the
            <Link to="/signin?resetFlag=true"> Forgot password</Link> link to reset your password.
          </p>
        </div>
      );
      setErrors(validationErrors);
      return;
    }

    try {
      const user = await signUp(email, password);
      // create userprofile in db tenant/1001/user/uid
      console.log("User signed up:", user);
      const userProfile = {
        uid: user.uid,
        entityId,
        firstName,
        lastName,
        email
      };
      const resp = await createUserProfile(userProfile);
      setAuthenticatedUser(true);
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        let validationErrors = {};
        validationErrors.userAlreadyExist = (
          <div>
            <p style={{ color: 'red', fontWeight: 'bold' }}>
              User is already registered with this email address.
            </p>
            <p style={{ color: 'red' }}>
              Please choose another email address to register.
              If you have forgotten your password, please click on the
              <Link to="/signin?resetFlag=true"> Forgot password</Link> link to reset your password.
            </p>
          </div>
        );
        setErrors(validationErrors);
      }
      console.error("Sign-up error:", error.message);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const validateSignUpForm = () => {
    setErrors({});
    let validationErrors = {};

    let flag = true;

    if (isNullOrUndefinedOrEmpty(entityId)) {
      validationErrors.entityId = 'Entity Id is required.';
      flag = false;
    }
    // Validate first name 
    if (isNullOrUndefinedOrEmpty(firstName)) {
      validationErrors.firstName = 'First Name is required.';
      flag = false;
    }

    // Validate last name
    if (isNullOrUndefinedOrEmpty(lastName)) {
      validationErrors.lastName = 'Last Name is required.';
      flag = false;
    }

    // Validate email
    if (isNullOrUndefinedOrEmpty(email)) {
      validationErrors.email = 'Email is required.';
      flag = false;
    } else if (!isValidEmail(email)) {
      flag = false;
      validationErrors.email = 'Please enter the valid email address.';
    }
    // Validate confirm email
    if (isNullOrUndefinedOrEmpty(confirmEmail)) {
      validationErrors.confirmEmail = 'Confirm Email is required.';
      flag = false;
    } else if (!isValidEmail(confirmEmail)) {
      flag = false;
      validationErrors.confirmEmail = 'Please enter the valid email address.';
    } else if (confirmEmail !== email) {
      validationErrors.confirmEmail = 'Emails do not match.';
      flag = false;
    }
    // Validate password
    if (isNullOrUndefinedOrEmpty(password)) {
      validationErrors.password = 'Password is required.';
      flag = false;
    } else {
      let passwordErrors = validatePassword(password);
      if (!isNullOrUndefinedOrEmpty(passwordErrors)) {
        flag = false;
        validationErrors.password = passwordErrors;
      }
    }

    if (!flag) {
      setErrors(validationErrors);
    }
    return flag;
  }

  const validatePassword = (password) => {
    const errors = [];

    // Firebase requires a minimum length of 6
    if (password.length < 6) {
      errors.push("Password must be at least 6 characters long.");
    }

    // Add any additional rules based on your requirements
    if (!/[A-Z]/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }

    if (!/[a-z]/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }

    if (!/[0-9]/.test(password)) {
      errors.push("Password must contain at least one number.");
    }

    if (!/[@$!%*?&#]/.test(password)) {
      errors.push("Password must contain at least one special character.");
    }

    const singleLineError = errors.join(' / ');
    return singleLineError;
  };

  return (
    <div>
      <section className="wrapper bg-light">
        <div className="container py-10">
          <div className="row text-center">
            <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto position-relative" data-cues="slideInDown"
              data-delay="600">
              <h2 className="fs-16 text-uppercase text-dark mb-2">Sign up to Chauffeurs </h2>
              <h3 className="display-3 mb-2"> Welcome to Chauffeurs</h3>
              <p className="fs-15 m-0">Registration takes less than a minute.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-xl-6 col-xxl-6 mx-auto" data-cues="slideInDown" data-delay="600">
              <div className="p-11 text-center">
                <form className="text-start mb-3">
                  <div className="form-floating mb-4">
                    <input type="text"
                      className={`form-control ${errors.entityId ? 'is-invalid' : ''}`}
                      placeholder="Entity Id" id="entityId" onChange={(e) => setEntityId(e.target.value)} />
                    <label htmlFor="entityId">Entity Id <span className="text-danger">*</span></label>
                    {errors.entityId && <div className="invalid-feedback">{errors.entityId}</div>}
                  </div>

                  <div className="form-floating mb-4">
                    <input type="text"
                      className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                      placeholder="First name" id="fName" onChange={(e) => setFirstName(e.target.value)} />
                    <label htmlFor="fName">First Name <span className="text-danger">*</span></label>
                    {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
                  </div>

                  <div className="form-floating mb-4">
                    <input type="text"
                      className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                      placeholder="Last name" id="lName" onChange={(e) => setLastName(e.target.value)} />
                    <label htmlFor="lName">Last Name <span className="text-danger">*</span></label>
                    {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
                  </div>

                  <div className="form-floating mb-4">
                    <input type="email"
                      className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                      placeholder="Email"
                      id="loginEmail"
                      onChange={(e) => setEmail(e.target.value)} />
                    <label htmlFor="loginEmail">Email <span className="text-danger">*</span></label>
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                  </div>
                  <div className="form-floating mb-4">
                    <input type="email"
                      className={`form-control ${errors.confirmEmail ? 'is-invalid' : ''}`}
                      placeholder="Confirm Email address"
                      id="confirmEmail"
                      onChange={(e) => setConfirmEmail(e.target.value)} />
                    <label htmlFor="confirmEmail">Confirm Email address <span className="text-danger">*</span></label>
                    {errors.confirmEmail && <div className="invalid-feedback">{errors.confirmEmail}</div>}
                  </div>
                  <div className="form-floating password-field mb-4 position-relative">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                      placeholder="Password"
                      id="loginPassword"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="loginPassword">Password <span className="text-danger">*</span></label>
                    {errors.password && <div className="invalid-feedback">{errors.password}</div>}

                    <span
                      className="password-toggle position-absolute end-0 top-50 translate-middle-y me-2 cursor-pointer"
                      onClick={togglePasswordVisibility}
                      style={{ cursor: 'pointer' }}
                    >
                      <i className={`uil ${isPasswordVisible ? 'uil-eye-slash' : 'uil-eye'}`}></i>
                    </span>
                  </div>
                  <input type="button" className="btn btn-secondary rounded btn-login w-100 mb-2"
                    onClick={handleSignUp}
                    value="Sign Up" />
                </form>
                {/* Display error message */}
                {errors.userAlreadyExist && (
                  <div className="error-message">
                    {errors.userAlreadyExist}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Signup;