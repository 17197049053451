
const paymentAPIBaseUrl = process.env.REACT_APP_PAYMENT_API_URL;
const paymentAPIKey = process.env.REACT_APP_PAYMENT_API_KEY;

export const getHeadersForPaymentAPI = () => {
    return {
        "Content-Type": "application/json",
        "x-api-key": paymentAPIKey
    };
};

export async function refundPaymentWithFees(paymentId) {
    const res = await fetch(`${paymentAPIBaseUrl}/refundFullPayment`, {
        method: "POST",
        headers: getHeadersForPaymentAPI(),
        body: JSON.stringify({
            paymentIntentId: paymentId,
        }),
    });
    const response = await res.json();
    return response;
}

export async function refundPaymentWithoutFees(paymentId) {
    const res = await fetch(`${paymentAPIBaseUrl}/refundPartialPayment`, {
        method: "POST",
        headers: getHeadersForPaymentAPI(),
        body: JSON.stringify({
            paymentIntentId: paymentId,
        }),
    });
    const response = await res.json();
    return response;
}

export async function getBookingFees(paymentId) {
    const res = await fetch(`${paymentAPIBaseUrl}/getTransactionFee`, {
        method: "POST",
        headers: getHeadersForPaymentAPI(),
        body: JSON.stringify({
            paymentIntentId: paymentId,
        }),
    });
    const response = await res.json();
    return response;
}