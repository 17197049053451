import React, { useState, useEffect } from 'react';
import { getChauffeurs } from '../../db/chauffeurDAO';

function ChauffeurList({ onSelectChauffeur }) {
    const [chauffeurs, setChauffeurs] = useState([]);

    useEffect(() => {
        const fetchChauffeurs = async () => {
            const chauffeursList = await getChauffeurs();
            setChauffeurs(chauffeursList);
        };
        fetchChauffeurs();
    }, []);

    return (
        <div className="container">
            <h3 className="display-4 mb-4 text-center">Assign Chauffeur</h3>
            <div className="table-responsive">
                <table className="table table-hover table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Chauffeur Details</th>
                            <th className="text-center" scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {chauffeurs.map(chauffeur => (
                            <tr key={chauffeur.id}>
                                <td>
                                    <div className="d-flex flex-column">
                                        <strong>{chauffeur.firstName}&nbsp;{chauffeur.lastName}</strong>
                                        <span>Email: {chauffeur.contact.email}</span>
                                        <span>Mob: {chauffeur.contact.phone}</span>
                                    </div>
                                </td>
                                <td className="text-center">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => onSelectChauffeur(chauffeur)}
                                    >
                                        Assign
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ChauffeurList;
