import { db } from '../config/firebase-config';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import * as FIREBASE_PATHS from '../config/firebase-path-mapping';
import * as genericDAO from './genericDAO';

export async function saveChauffeur(data) {

    try {
        const now = Timestamp.fromDate(new Date());
        data.creationDate = now;

        const chauffeurCollection = collection(db, FIREBASE_PATHS.CHAUFFEUR_COLLECTION_PATH);
        let response = await addDoc(chauffeurCollection, data);
        console.log('Chauffeur created successfully ' + response.id);
        return response.id;
    } catch (error) {
        console.error('Error while creating the chauffeur', error);
        return -1;
    }
};

export async function getChauffeurs() {
    return await genericDAO.getDocuments(FIREBASE_PATHS.CHAUFFEUR_COLLECTION_PATH);
}