import { React } from 'react';
import AddChauffeur from './addChauffeur';
import Menu from '../common/menu';



function MainChauffeur() {

    return (
        <div className="content-wrapper">
                  <Menu /> 
            <AddChauffeur/>
         </div>
    );
}

export default MainChauffeur