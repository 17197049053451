
/**
 * Checks if a value is null, undefined, or an empty string.
 * @param value - The value to check.
 * @returns True if the value is null, undefined, or an empty string; otherwise, false.
 */
export function isNullOrUndefinedOrEmpty(value) {
    if (value === null || value === undefined || value === '') {
        return true;
    }
    if (typeof value === 'string') {
        value = value.trim();
    } else if (typeof value === 'number') {
        value = value.toString().trim(); // Convert number to string
    }
    return value === '';
}

export function isValidBookingReferenceId(bookingReferenceId, minLength = 8, maxLength = 12) {
    const bookingReferenceIdRegex = /^[a-zA-Z0-9]+$/;
    return typeof bookingReferenceId === 'string' &&
        bookingReferenceId.length >= minLength &&
        bookingReferenceId.length <= maxLength &&
        bookingReferenceIdRegex.test(bookingReferenceId);
}

export function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return typeof email === 'string' && emailRegex.test(email);
}

export function convertTo12HourFormat(time) {
    if (isNullOrUndefinedOrEmpty(time)) {
        return;
    }
    const [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hour12 = hours % 12 || 12; // Convert 0 hours to 12
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${hour12}:${formattedMinutes} ${period}`;
}