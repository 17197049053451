import React from 'react';

function Footer() {

    const logoStyle = {
        width: 200,  // Use number for pixel value
        height: 100   // Use number for pixel value
    };

    return (
        <footer className="bg-primary text-inverse">
            <div className="container py-13 py-md-15">
                <div className="row gy-4 gy-lg-0">
                    <div className="col-md-12 col-lg-3" data-cues="slideInDown" data-delay="600">
                        <div className="widget">
                            <a href="./">
                                <img src="./assets/img/bcc_logo_trans.png" srcSet="./assets/img/bcc_logo_trans.png 2x" alt="" style={logoStyle} />
                            </a>
                            <p className="mb-4">Business Class Chauffeur offers premium transportation services, providing a seamless and luxurious travel experience for individuals and businesses.</p>
                            <p className="mb-4">© 2024 Business Class Chauffeur. <br className="d-none d-lg-block" />All rights reserved.</p>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-9">
                        <div className="row gy-4 gy-lg-0 p-0 m-0">
                            <div className="col-md-6 col-lg-3 px-md-2 px-0 mb-md-0 mb-4" data-cues="slideInDown" data-delay="600">
                                <div className="widget">
                                    <h6 className="widget-title text-uppercase text-white mb-3">Get in Touch</h6>
                                    <address>{process.env.REACT_APP_BUSINESS_ADDRESS}</address>
                                    <p>
                                        <a href={`tel:${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}`} className="link-white">{process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</a>
                                    </p><p>

                                        <a href={`mailto:${process.env.REACT_APP_BUSINESS_SUPPORT_EMAIL}`} className="link-body">
                                            {process.env.REACT_APP_BUSINESS_SUPPORT_EMAIL}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </footer>
    );
}

export default Footer;