import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';
import messages from './i18n/messages';
import { AuthProvider } from './contexts/authContext';

const locale = 'en';

const MyComponent = () => {
  return (
    <React.StrictMode>
      <AuthProvider>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <App />
        </IntlProvider>
      </AuthProvider>
    </React.StrictMode>
  );
};

console.log('before initialization -------> ');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<MyComponent />);

console.log('after initialization -------> ');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
