import { auth, db } from "../config/firebase-config";
import { doc, getDoc } from "firebase/firestore";


import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail
} from "firebase/auth";

export const isValidAdminUser = async (email) => {

    const allowedAdminsRef = doc(db, "config", "allowedAdmins");
    const allowedAdminsDoc = await getDoc(allowedAdminsRef);

    if (allowedAdminsDoc.exists()) {
        const allowedEmails = allowedAdminsDoc.data().administrators || [];
        return allowedEmails.includes(email);
    } else {
        console.error("No allowed admin emails found in System.");
        return false;
    }
}

// Sign up function
export const signUp = async (email, password) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        return userCredential.user;
    } catch (error) {
        console.error("Error signing up:", error.message);
        throw error;
    }
};

// Sign in function
export const signIn = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        return userCredential.user;
    } catch (error) {
        console.error("Error signing in:", error.message);
        throw error;
    }
};

export const passwordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        return true;
    } catch (error) {
        console.error("Error in password reset:", error.message);
        return false;
    }
};

// Sign out function
export const signOutUser = async () => {
    try {
        await signOut(auth);
    } catch (error) {
        console.error("Error signing out:", error.message);
        throw error;
    }
};