import React from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import SuccessPage from './userSuccesPage';


function MainAccountSignupSuccess() {
  return (
    <div className="content-wrapper">
      <Menu />
      <SuccessPage />
      <Footer />
    </div>
  );
}

export default MainAccountSignupSuccess;