import { React } from 'react';
import Menu from '../common/menu';
import Bookings from './bookings';

function MainBookings() {
  return (
    <div className="content-wrapper">
      <Menu />
      <Bookings />
    </div>
  );
}


export default MainBookings;