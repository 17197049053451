import React from 'react';
import { Link } from 'react-router-dom';

function SuccessPage() {
  return (
    <div className="container text-center py-5">
      <h1 className="display-4">Account Created Successfully!</h1>
      <p className="lead">Thank you for signing up. Your account has been created successfully.</p>
      <Link to="/" className="btn btn-primary mt-4">Go to Home</Link>
    </div>
  );
}

export default SuccessPage;