export const loadExternalScripts = async () => {
    const script = document.createElement('script');
    script.src = './assets/js/theme.js';
    document.body.appendChild(script);
    console.log('Script loaded successfully ------->');
};

export const removeExternalScripts = () => {
    const script = document.querySelector('script[src="./assets/js/theme.js"]');
    if (script) {
        script.remove();
        console.log('Script removed successfully ------->');
    }
};