import React, { useState, useEffect } from 'react';
import { getBookingsStream } from '../../db/bookingsDAO';
import Spinner from 'react-bootstrap/Spinner';
import BookingStatus from '../enums/BookingStatus';
import { convertTo12HourFormat } from '../common/utilfn';
import AssignBooking from '../manage-booking/assign-booking';
import { useNavigate } from 'react-router-dom';

function Bookings() {
  const [bookingsData, setBookingsData] = useState([]);
  const [confirmedCount, setConfirmedCount] = useState(0);
  const [driverAssignedCount, setDriverAssignedCount] = useState(0);
  const [cancelledBookingCount, setCancelledBookingCount] = useState(0);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [currentTab, setCurrentTab] = useState(BookingStatus.CONFIRMED);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = getBookingsStream((data, status) => {
      if (status === BookingStatus.CONFIRMED) {
        setConfirmedCount(data.length);
      } else if (status === BookingStatus.DRIVER_ASSIGNED) {
        setDriverAssignedCount(data.length);
      } else if (status === BookingStatus.CANCELLED) {
        setCancelledBookingCount(data.length);
      }

      if (status === currentTab) {
        setBookingsData(data);
        setLoading(false);
      }
    }, [BookingStatus.CONFIRMED, BookingStatus.DRIVER_ASSIGNED, BookingStatus.CANCELLED]);

    return () => unsubscribe && unsubscribe();
  }, [currentTab]);

  const handleTabClick = (tab) => {
    setCurrentTab(tab);
    setSelectedBooking(null); // Reset selected booking when switching tabs
    setLoading(true); // Show loading spinner when switching tabs
  };

  const handleDetailClick = (booking) => {
    navigate('/assign-booking', { state: { booking: booking } });
    //setSelectedBooking(booking);
  };

  const handleBackClick = () => {
    setSelectedBooking(null);
  };

  return (
    <div className="container-fluid pt-4 px-4">
      <div className="bg-light text-center rounded p-4">
        <nav className="d-flex justify-content-center mb-4">
          <div
            className={`px-4 py-2 ${currentTab === BookingStatus.CONFIRMED ? 'bg-primary text-white' : 'bg-light text-dark'} cursor-pointer`}
            onClick={() => handleTabClick(BookingStatus.CONFIRMED)}
            style={{ borderRadius: '5px', marginRight: '8px' }}
          >
            New Bookings ({confirmedCount})
          </div>
          <div
            className={`px-4 py-2 ${currentTab === BookingStatus.DRIVER_ASSIGNED ? 'bg-primary text-white' : 'bg-light text-dark'} cursor-pointer`}
            onClick={() => handleTabClick(BookingStatus.DRIVER_ASSIGNED)}
            style={{ borderRadius: '5px' }}
          >
            Assigned Bookings ({driverAssignedCount})
          </div>
          <div
            className={`px-4 py-2 ${currentTab === BookingStatus.CANCELLED ? 'bg-primary text-white' : 'bg-light text-dark'} cursor-pointer`}
            onClick={() => handleTabClick(BookingStatus.CANCELLED)}
            style={{ borderRadius: '5px' }}
          >
            Cancelled Bookings ({cancelledBookingCount})
          </div>
        </nav>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center py-4">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : selectedBooking ? (
          <AssignBooking booking={selectedBooking} />
        ) : (
          <div className="table-responsive" style={{ maxWidth: '800px', margin: '0 auto' }}>
            <table className="table text-start align-middle table-bordered table-hover mb-0">
              <thead>
                <tr className="text-dark">
                  <th scope="col">Ref#</th>
                  <th scope="col">Booking Date</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {bookingsData.map((booking) => (
                  <tr key={booking.refId}>
                    <td>{booking.bookingReferenceId || ''}</td>
                    <td>
                      {booking.generalDetails?.bookingDate || ''} &nbsp;
                      {convertTo12HourFormat(booking.generalDetails?.bookingTime || '')}
                    </td>
                    <td>${booking.bookingFee || ''}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => handleDetailClick(booking)}
                      >
                        Detail
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default Bookings;
