import React, { useState, useEffect } from 'react';
import { convertTo12HourFormat } from '../common/utilfn';
import ChauffeurList from './chauffeurList';
import { useLocation, useNavigate } from 'react-router-dom';
import BookingStatus from '../enums/BookingStatus';
import { updateBookingStatus } from '../../db/bookingsDAO';
import { useAuth } from '../../contexts/authContext';

function AssignBooking() {
    const location = useLocation();
    const navigate = useNavigate();
    const [bookingDetails, setBookingDetails] = useState(location.state?.booking || {});
    const [showChauffeurList, setShowChauffeurList] = useState(false);
    const [selectedChauffeur, setSelectedChauffeur] = useState(null);
    const { currentUser } = useAuth();


    useEffect(() => {
        setBookingDetails(location.state?.booking);
    }, [location.state?.booking]);

    const assignChauffeur = async () => {
        setShowChauffeurList(true);
    };

    const handleAssign = async (driver) => {
        // Create a consistent driver object format when assigning
        const driverData = {
            driverId: driver.refId,
            firstName: driver.firstName,
            lastName: driver.lastName,
            email: driver.contact.email,
            mobileNumber: driver.contact.phone
        };
        let id = bookingDetails.refId;
        await updateBookingStatus(id, BookingStatus.DRIVER_ASSIGNED, driverData, currentUser.uid);


        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            bookingStatus: BookingStatus.DRIVER_ASSIGNED
        }));

    };

    const handleUnassign = async () => {
        let id = bookingDetails.refId;
        await updateBookingStatus(id, BookingStatus.CONFIRMED, null, currentUser.uid);
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            bookingStatus: BookingStatus.CONFIRMED
        }));
    };


    const selectChauffeur = async (chauffeur) => {
        await handleAssign(chauffeur);
        setSelectedChauffeur(chauffeur);
        setShowChauffeurList(false);
    };

    const unassignChauffeur = async () => {
        await handleUnassign();
        setSelectedChauffeur(null);
    };

    return (
        <section className="wrapper bg-light">
            <div className="container pb-14 pb-md-16">
                <div className="mb-3">
                    <button className="btn btn-secondary" onClick={() => navigate(-1)}>
                        Back
                    </button>
                </div>
                <div className="row mb-5">
                    <div className="col-md-10 col-lg-12 col-xl-10 col-xxl-9 mx-auto text-center">
                        <br /><br />
                        <h3 className="display-3 mb-2">Booking Detail</h3>
                        <p className="fs-15">Check booking details and assign the booking to the Chauffeur.
                        </p>
                    </div>
                </div>

                <>
                    <div className="container">
                        <div className="row grid-view gx-md-8 gx-xl-10 gy-6 gy-lg-0 mb-5">
                            <div className="col-12">
                                <div className="form-heading">
                                    <h3>Booking Details</h3>
                                </div>
                            </div>

                            <div className="col-12 mb-4">
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-location-pin-alt"></i> </div>
                                    </div>
                                    <div className="w-100 align-self-start justify-content-start">
                                        <h5 className="mb-1 text-secondary">Address</h5>
                                        {!bookingDetails.location.stopLocationFlag ? (
                                            <div className="d-flex align-items-center justify-content-between">
                                                <address>From : <span className="text-muted">{bookingDetails.location.pickupLocation}</span></address>
                                                <address>To : <span className="text-muted">{bookingDetails.location.dropLocation}</span></address>
                                            </div>
                                        ) :
                                            <>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <address>From : <span className="text-muted">{bookingDetails.location.pickupLocation}</span></address>
                                                    <address>Stop : <span className="text-muted">{bookingDetails.location.stopLocation}</span></address>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <address>Stop : <span className="text-muted">{bookingDetails.location.stopLocation}</span></address>
                                                    <address>To : <span className="text-muted">{bookingDetails.location.dropLocation}</span></address>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-calendar-alt"></i> </div>
                                    </div>
                                    <div className="align-self-start justify-content-start">
                                        <h5 className="mb-1 text-secondary">Booking Details</h5>
                                        <div className="d-block">Ref# : <span className="text-muted">{bookingDetails?.bookingReferenceId}</span></div>
                                        <div className="d-block">Status : <span className="text-muted">{bookingDetails?.bookingStatus}</span></div>
                                        <div className="d-block">Booking Amount : <span className="text-muted">{bookingDetails?.bookingFee}</span></div>
                                        {bookingDetails?.refundAmount && bookingDetails.refundAmount > 0 && (
                                            <div className="d-block">
                                                Refund Amount : <span className="text-muted">{bookingDetails.refundAmount}</span>
                                            </div>

                                        )}
                                        {bookingDetails?.bookingStatus === BookingStatus.CANCELLED && bookingDetails?.cancellationReason && (
                                            <div className="d-block">
                                                Reason for cancellation: <span className="text-muted">{bookingDetails.cancellationReason}</span>
                                            </div>
                                        )}
                                        <div className="d-block">Pickup Date : <span className="text-muted">{bookingDetails?.generalDetails.bookingDate}</span></div>
                                        <div className="d-block">Pickup Time : <span className="text-muted">{convertTo12HourFormat(bookingDetails?.generalDetails.bookingTime)}</span></div>
                                        <div className="d-block">Passengers : <span className="text-muted">{bookingDetails?.generalDetails.passengersCount}</span></div>
                                        <div className="d-block">Distance : <span className="text-muted">{bookingDetails?.distanceMatrix.distance.text}</span></div>
                                        {bookingDetails.generalDetails.byTheHourCheck && (
                                            <div className="d-block">Booked for : <span className="text-muted">{bookingDetails.generalDetails.numberOfHours} hours</span></div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-calendar-alt"></i> </div>
                                    </div>
                                    <div className="align-self-start justify-content-start">
                                        <h5 className="mb-1 text-secondary">Passenger Details</h5>
                                        <div className="d-block">Name : <span className="text-muted">{bookingDetails?.passengerDetails.firstName} {bookingDetails?.passengerDetails.lastName}</span></div>
                                        <div className="d-block">Email : <span className="text-muted">{bookingDetails?.passengerDetails.email}</span></div>
                                        <div className="d-block">Mobile : <span className="text-muted">{bookingDetails?.passengerDetails.countryCode || '+61'} - {bookingDetails?.passengerDetails.mobileNumber}</span></div>
                                    </div>
                                </div>
                            </div>
                            {bookingDetails?.passengerDetails.onBehalfCheck && (
                                <div className="col-12 mb-4">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-calendar-alt"></i> </div>
                                        </div>
                                        <div className="align-self-start justify-content-start">
                                            <h5 className="mb-1 text-secondary">Booking Person Details</h5>
                                            <div className="d-block">Name : <span className="text-muted">{bookingDetails?.passengerDetails.bookingPerson.firstName} {bookingDetails?.passengerDetails.lastName}</span></div>
                                            <div className="d-block">Email : <span className="text-muted">{bookingDetails?.passengerDetails.bookingPerson.email}</span></div>
                                            <div className="d-block">Mobile : <span className="text-muted">{bookingDetails?.passengerDetails.bookingPerson.countryCode || '+61'} - {bookingDetails?.passengerDetails.bookingPerson.mobileNumber}</span></div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="col-12 mb-4">
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-taxi"></i> </div>
                                    </div>
                                    <div className="align-self-start justify-content-start">
                                        <h5 className="mb-1 text-secondary">Vehicle Details</h5>
                                        <div className="d-block">Vehicle : <span className="text-muted"> {bookingDetails?.vehicleSelection.name} </span></div>
                                        <div className="d-block">Type : <span className="text-muted"> {bookingDetails?.vehicleSelection.type} </span></div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {!selectedChauffeur && (bookingDetails?.bookingStatus === BookingStatus.DRIVER_ASSIGNED) && (
                            <div className="card mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">Chauffeur Details</h5>
                                    <p className="card-text">Name: {bookingDetails?.driverAssigned.firstName}&nbsp;{bookingDetails?.driverAssigned.lastName}</p>
                                    <p className="card-text">Email: {bookingDetails?.driverAssigned.email}</p>
                                    <p className="card-text">Phone: {bookingDetails?.driverAssigned.mobileNumber}</p>
                                    <button className="btn btn-danger" onClick={unassignChauffeur}>
                                        Unassign Chauffeur
                                    </button>
                                </div>
                            </div>
                        )}

                        {selectedChauffeur && (
                            <div className="card mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">Chauffeur Details</h5>
                                    <p className="card-text">Name: {selectedChauffeur.firstName}&nbsp;{selectedChauffeur.lastName}</p>
                                    <p className="card-text">Email: {selectedChauffeur.contact.email}</p>
                                    <p className="card-text">Phone: {selectedChauffeur.contact.phone}</p>
                                    <button className="btn btn-danger" onClick={unassignChauffeur}>
                                        Unassign Chauffeur
                                    </button>
                                </div>
                            </div>
                        )}
                        <div className="d-flex justify-content-between mb-4">
                            <button className="btn btn-secondary" onClick={() => navigate(-1)}>
                                Back
                            </button>

                            {!selectedChauffeur && (bookingDetails?.bookingStatus === BookingStatus.CONFIRMED) && (
                                <button className="btn btn-primary" onClick={() => assignChauffeur()}>
                                    Assign Chauffeur
                                </button>
                            )}
                        </div>
                        {showChauffeurList && (
                            <ChauffeurList onSelectChauffeur={selectChauffeur} />
                        )}
                    </div>
                    <div className="container">
                    </div>
                </>
            </div>
        </section >
    );
}

export default AssignBooking;