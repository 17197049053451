import React from 'react';
import { useLocation } from 'react-router-dom';
import Menu from '../common/menu';
import Footer from '../common/footer';
import Signin from './signin';


function MainAccount() {

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const resetFlag = query.get('resetFlag') === 'true'; // Convert to boolean

  return (
    <div className="content-wrapper">
      <Menu />
      <Signin resetFlag={resetFlag} />
      <Footer />
    </div>
  );
}

export default MainAccount;